<template>
  <div>
    <div class="wrapper">
      <!-- section prices -->
      <section
        id="step-account"
        class="section jpadding jpadding-20 section-unfixed"
        style="background: #1193f5"
      >
        <div class="steps-container">
          <a href="#" class="steps-nav-item">Step 1</a>
          <a href="#" class="steps-nav-item active">Step 2</a>
        </div>
        <div class="section-detail">
          <!-- 
            <div class="illustration">
                <img src="/img/vectors/talkii-illustration2.svg" alt="">
            </div> -->
          <!-- <div class="mb-3 jcard jcard-padding-30 jcard-nohover">
          <p>Talkii {{ title }}</p>
          <p>{{ PLAN.USERS }} user(s)</p>
          <p></p>
          <p>
            Total <strong>{{ PLAN.PRICE }}€</strong>
          </p>
        </div> -->
          <div
            id="plan-detail"
            class="jcard jcard-detail price-platform price-sm"
          >
            <h5>TALKII {{ title }}</h5>
            <div>
              <h3 class="mb-0">{{ PLAN.PRICE }}€</h3>
              <p>
                <i>billed {{ billed }}</i>
              </p>
            </div>
            <p>
              <small
                >You’ll be charged €{{ PLAN.PRICE }} {{ billed }} until you
                cancel your subscription.</small
              >
            </p>
            <div class="jcard-separator"></div>
            <div class="jcard-items-list">
              <p><i class="fal fa-user"></i></p>
              <p>{{ PLAN.USERS }} user(s)</p>
              <p><i class="fal fa-mobile-android"></i></p>
              <p>Supervisor App</p>
              <p><i class="fal fa-tablet-android"></i></p>
              <p>Tablet App</p>
            </div>

            <router-link class="jbtn jbtn-blue" :to="{ name: 'Password' }">
              Continue
            </router-link>
          </div>

          <div class="jcard jcard-main jcard-register jcard-nohover">
            <div class="mb-3" v-if="displayBilling">
              <div class="sub-title mb-4">
                <h3>Billing cycle</h3>
              </div>
              <div class="cycles-container">
                <div class="jcard-mini jcard-mini-check">
                  <div>
                    <p><i class="fal fa-circle"></i></p>
                  </div>
                  <div>
                    <p><b>Monthly</b></p>
                    <p>19.99€ / month</p>
                  </div>
                </div>

                <div class="jcard-mini jcard-mini-check">
                  <div>
                    <p><i class="fal fa-circle"></i></p>
                  </div>
                  <div>
                    <p><b>6 months</b></p>
                    <p>17.99€ / month</p>
                  </div>
                </div>

                <div class="jcard-mini jcard-mini-check">
                  <div>
                    <p><i class="fal fa-circle"></i></p>
                  </div>
                  <div>
                    <p><b>Yearly</b></p>
                    <p>15.99€ / month</p>
                  </div>
                </div>

                <div class="jcard-mini jcard-mini-check">
                  <div>
                    <p><i class="fal fa-circle"></i></p>
                  </div>
                  <div>
                    <p><b>3 years</b></p>
                    <p>13.99€ / month</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="sub-title mb-4">
              <h3>Account</h3>
            </div>

            <div class="login">
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <label for="email">
                      <i class="fad fa-user"></i> First Name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="userData.firstName"
                    />
                  </div>
                </div>

                <div class="col">
                  <div class="form-group">
                    <label for="email"
                      ><i class="fad fa-user"></i> Last Name</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      v-model="userData.lastName"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="email"
                  ><i class="fad fa-map-marker"></i> Address</label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="userData.address"
                />
              </div>

              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <label for="email"
                      ><i class="fad fa-map-marker"></i> Zip</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      v-model="userData.zip"
                    />
                  </div>
                </div>

                <div class="col">
                  <div class="form-group">
                    <div class="form-group">
                      <label for="email"
                        ><i class="fad fa-phone-alt"></i> Phone</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        v-model="userData.phone"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="email"
                  ><i class="fad fa-globe-europe"></i> Country</label
                >
                <b-form-select
                  v-model="userData.country"
                  :options="countriesList"
                ></b-form-select>
              </div>

              <div class="form-group">
                <label for="email"><i class="fad fa-envelope"></i> Email</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="userData.email"
                />
                <div class="invalid-feedback">
                  Please provide a valid city.
                </div>
              </div>

              <div class="form-group">
                <label for="password"
                  ><i class="fad fa-lock"></i> Password</label
                >
                <input
                  type="password"
                  class="form-control"
                  v-model="userData.password"
                />
              </div>

              <div class="form-group">
                <label for="password"
                  ><i class="fad fa-lock"></i> Repeat password</label
                >
                <input
                  type="password"
                  class="form-control"
                  v-model="userData.passwordCheck"
                />
              </div>

              <div ref="paypal"></div>

              <div class="btn-container text-center mt-4 mb-4">
                <button
                  class="jbtn jbtn-blue"
                  value="Add"
                  @click="registerUser"
                >
                  Singup!
                </button>
              </div>

              <div class="login-message">
                <p>{{ validationMsg }}</p>
                <p>{{ pwNotOkMsg }}</p>
              </div>

              <div class="bottom-links text-center">
                <router-link to="/login"
                  ><p><a>Already have an account?</a></p></router-link
                >
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <section class="separator separator-lux jpadding">
      <img src="@/assets/logos/made-in-lux.svg" alt="Made in Luxembourg" />
    </section>
  </div>
</template>

<script>
import firebase from "firebase/app";
import db from "../../firebase/firebaseInit";
import axios from "axios";
import C from "@/constants";
import PLANS from "@/plans";

export default {
  name: "CreateAccount",
  data() {
    return {
      //global
      planId: 0, // controller
      title: PLANS.ALL[0].NAME,
      billed: PLANS.BILLED_INDEX[PLANS.ALL[0].BILLED],
      PLAN: PLANS.ALL[0],
      isPackage: false,
      userId: null,

      buy: {
        hasUser: false,
        product: PLANS.PRODUCT.BASIC,
        type: PLANS.TYPE.APP,
        plan: null,
      },

      //display
      displayBilling: false,

      //form input data
      userData: {
        firstName: "",
        lastName: "",
        address: "",
        zip: "",
        country: "Luxembourg",
        phone: "",
        email: "",
        password: "",
        passwordCheck: "",
      },
      //firebase document
      userDoc: {
        ready: false,
        admin: false,
        premium: false,

        aacType: 2, // platform_account
        status: 5, // inactive
        sku: null, // subscription type
        expiry: Math.floor(Date.now()), // in ms

        token: null, // ignore

        ic: "",
        name: "",
        email: "",
        addr: "",
        zip: "",
        country: "",
        phone: "",

        children: new Array(),
        chName: new Array(),
        chColor: new Array(),
        chLang: new Array(),
        chLayout: new Array(),
        chColumn: new Array(),
        chShowTitle: new Array(),
        chAiVoice: new Array(),
        chTlWeekMode: new Array(),
        chShowTime: new Array(),
        cTlCount: new Array(),
        eTlLastAcc: new Array(),
        active: -1,
        tlColors: [
          "#d3d3d3",
          "#d3d3d3",
          "#d3d3d3",
          "#d3d3d3",
          "#d3d3d3",
          "#d3d3d3",
          "#d3d3d3",
        ],
        eTlMode: false,
        upV: Math.floor(Date.now()),
      },

      countriesList: C.COUNTRIES,
      validEmail: true,
      validFirstName: true,
      validLastName: true,
      validPassword: true,

      validationMsg: "",
      pwNotOkMsg: "",

      //paypal
      loaded: false,
      paidFor: false,
      product: {
        price: 33.33,
        description: "Talkii Package",
        img: "@/assets/talkii-logo-gradient.png",
      },
      //subscription
      subscription: {
        userId: null, //firebase.auth().currentUser.uid //from firebase
        aacType: 0, //subscription type, from plan
        planId: 0, //sku, from plan
        planName: "", //subscription name, from plan
        start: Date.now, //from paypal
        expiry: Date.now, //from paypal
        duration: 0, //from plan
        price: 0, //from plan
        orderId: "NULL", //from paypal
        isPaid: false, //from paypal
        deliveryId: "NULL",
        isDelivered: false,
        status: PLANS.STATUS.FAILED, //from paypal
        //description: "",
      },
    };
  },
  //   computed: {
  //     buy: {
  //       get() {
  //         return this.$store.getters.getBuy;
  //       },
  //     },
  //   },
  mounted: function() {
    //skip if user already logged in
    var user = firebase.auth() ? firebase.auth().currentUser : null;

    if (user) {
      // User is signed in.

      //todo: react signed in user with query or/and no buy
      //set plan in store if query is set
      //   if (this.$route.query.buy) {
      //   }

      this.$router.push({
        name: "Payment",
      });
      //check store state buy
    } else {
      // No user is signed in.
      this.init();
    }
  },
  methods: {
    //init page
    init() {
      console.log("store plan: " + this.buy.plan);

      //todo: check/load query -> check/load store -> load default plan
      //selected plan

      if (!this.$route.query.buy) {
        console.log("No plan selected via query. Default plan (0) was loaded.");
        //load store here
      } else {
        //init global data from plan with query
        if (C.isInt(this.$route.query.buy)) console.log("is valid input");
        this.planId = this.$route.query.buy;
        this.title = PLANS.ALL[this.planId].NAME;
        this.billed = PLANS.BILLED_INDEX[PLANS.ALL[this.planId].BILLED];
        this.PLAN = PLANS.ALL[this.planId];
        if (this.PLAN.TYPE == 1) {
          this.isPackage = true;
          console.log("buy: is package");
        }
        //init subscription data from plan
        this.subscription.aacType = this.PLAN.TYPE;
        this.subscription.planName = this.PLAN.NAME;
        this.subscription.planId = this.planId;
        this.subscription.duration = this.PLAN.DURATION;
        this.subscription.price = this.PLAN.PRICE;
        console.log("Subscription price: " + this.subscription.price);
      }

      console.log("Selected Plan: " + this.planId);
      console.log(this.PLAN);

      //display billing
      if (this.$route.query.billing) {
        this.displayBilling = this.$route.query.billing;
      }
    },
    loadQuery() {},
    //called when PayPal sdk is loaded
    setLoaded: function() {
      this.loaded = true;
    },

    openNextStep() {
      this.$router.push({
        name: "Payment",
      });
    },

    registerUser() {
      // TODO: MORE INPUT VALIDATION

      if (this.validate()) {
        this.validationMsg = "SUCCESS";

        //update local userDoc
        this.userDoc.name =
          this.userData.firstName + " " + this.userData.lastName;
        this.userDoc.email = this.userData.email;
        this.userDoc.addr = this.userData.address;
        this.userDoc.zip = this.userData.zip;
        this.userDoc.country = this.userData.country;
        this.userDoc.phone = this.userData.phone;

        this.userDoc.aacType = this.isPackage ? 1 : 2;
        this.userDoc.status = PLANS.STATUS.EXPIRED;
        this.userDoc.sku = this.planId;

        //firebase auth
        firebase
          .auth()
          .createUserWithEmailAndPassword(
            //create auth user
            this.userData.email,
            this.userData.password
          )
          .then(() => {
            this.userId = firebase.auth().currentUser.uid;
            //update auth user
            firebase
              .auth()
              .currentUser.updateProfile({
                displayName:
                  this.userData.firstName + " " + this.userData.lastName,
              })
              .then(() => {
                //create user doc
                this.validationMsg =
                  "Welcome " + firebase.auth().currentUser.displayName;
                db.collection("users")
                  .doc(firebase.auth().currentUser.uid)
                  .set(this.userDoc)
                  .then(() => {
                    this.pwNotOkMsg = "";
                    // this.$router.replace("/");
                    console.log("Firebase userDoc created");
                    this.$store.commit("setUserDoc", this.userDoc);
                    this.$store.commit("updateBuy", "hasUser", true);
                    this.addPlatfromUser();
                    //this.$router.push("/");
                    this.openNextStep();
                  })
                  .catch((err) => {
                    this.pwNotOkMsg = "COULD NOT CREATE DOCUMENT";
                    this.validationMsg = err.message;
                  });
              })
              .catch((err) => {
                this.pwNotOkMsg = "COULD NOT UPDATE PROFILE";
                this.validationMsg = err.message;
              });
          })
          .catch((err) => {
            this.validationMsg = err.message;
            this.pwNotOkMsg = "COULD NOT CREATE USER";
          });
        this.pwNotOkMsg = "registration in progress";
      } else {
        this.validationMsg = "Please complete the form";
      }
    },
    addSubscription() {
      //   if (
      //     this.userId != "" &&
      //     this.aacType != "" &&
      //     this.expiry != "" &&
      //     this.orderId != ""
      //   ) {
      axios
        .post("/api/functions.php", {
          request: 2,
          userId: this.subscription.userId,
          aacType: this.subscription.aacType,
          planName: this.subscription.planName,
          planId: this.subscription.planId,
          start: this.subscription.start,
          expiry: this.subscription.expiry,
          duration: this.subscription.duration,
          price: this.subscription.price,
          orderId: this.subscription.orderId,
          isPaid: this.subscription.isPaid,
          deliveryId: this.subscription.deliveryId,
          isDelivered: this.subscription.isDelivered,
          status: this.subscription.status,
        })
        .then(function(response) {
          // Fetch records
          //app.allRecords();

          // Empty values
          //app.orderId = "";
          //alert(response.data);
          console.log(response.data);
        })
        .catch(function(error) {
          console.log(error);
        });
      //   } else {
      //     alert("Fill all fields.");
      //   }
    },
    addPlatfromUser() {
      if (this.userId != null && this.userId != "") {
        axios
          .post("/api/functions.php", {
            request: 3,
            userId: this.userId,
            has_active_subscription: false,
          })
          .then(function(response) {
            console.log(response.data);
          })
          .catch(function(error) {
            console.log(error);
          });
      } else {
        console.log("No Talkii UserID found. Unable to create Platform User.");
      }
    },
    getPlan() {
      axios
        .post("/api/functions.php", {
          request: 1,
        })
        .then(function(response) {
          this.PLAN = response.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    validate() {
      //TODO: use array so we see all the problems at once

      //Check first name
      if (this.userData.firstName.length < 2) {
        this.validFirstName = false;
        return false;
      }
      this.validFirstName = true;
      //Check last name
      if (this.userData.lastName.length < 2) {
        this.validLastName = false;
        return false;
      }
      this.validLastName = true;
      //Check email
      if (!this.checkEmail(this.userData.email)) {
        this.validEmail = false;
        return false;
      }
      this.validEmail = true;
      //Check password
      if (this.userData.password.length < 8) {
        this.validPassword = false;
        this.pwNotOkMsg = "Your password is too short (8 characters min.)";
        return false;
      }
      this.pwNotOkMsg = "";
      //Check confirm password
      if (this.userData.password != this.userData.passwordCheck) {
        this.validPassword = false;
        this.pwNotOkMsg = "Your passwords did not match";
        return false;
      }
      this.pwNotOkMsg = "";
      this.validPassword = true;
      return true;
    },
    checkEmail: function(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
  },
  created: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/plans.scss";
</style>
